/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ======== google fonts ======== */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: #091512;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  margin: 0;
  list-style: none;
}

.large-header {
  position: relative;
  width: 100%;
  height: 100vh;
  width: 100vw;
  background: #111;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.demo .large-header {
  background-color: #000000;
}

.main-title {
  position: absolute;
  margin: 0;
  padding: 0;
  color: #f9f1e9;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.demo .main-title {
  text-transform: uppercase;
  font-size: 4.2em;
  letter-spacing: 0.1em;

  text-transform: uppercase;
  font-size: 5.1em;
  letter-spacing: 0.1em;
  margin-top: 48px;
}

.main-title .thin {
  font-weight: 200;
}

@media only screen and (max-width: 768px) {
  .demo .main-title {
    font-size: 3em;
  }
}

/* body {
  padding: 3em;
} */
.centercenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.construction:after {
  background-color: #fdd400; /* colour of the sign */
  border-radius: 3em;
  content: "";
  height: 28em;
  left: -12em;
  position: absolute;
  top: 5em;
  transform: rotate(45deg);
  width: 28em;
  z-index: -1;
}
.construction:before {
  content: "";
  display: block;
  position: absolute;
  left: -17em;
  background-color: #000;
  font-size: 7px; /* pixel size */
  height: 1em;
  margin-bottom: 37em;
  margin-left: 17em;
  position: relative;
  width: 1em;

  box-shadow:
    /* row 1 */ 1em 0, 2em 0, 3em 0, /* row 2 */ -1em 1em, 0 1em,
    1em 1em, 2em 1em, 3em 1em, 4em 1em, /* row 3 */ -2em 2em, -1em 2em, 4em 2em,
    5em 2em, /* row 4 */ -3em 3em, -2em 3em, 5em 3em, 6em 3em,
    /* row 5 */ -4em 4em, -3em 4em, 6em 4em, 7em 4em, /* row 6 */ -5em 5em,
    -4em 5em, 7em 5em, 8em 5em, /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em,
    /* row 8 */ -7em 7em, -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em,
    10em 7em, /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em,
    3em 8em, 4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em,
    -1em 9em, 0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
    /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
    -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
    7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
    -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
    4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
    12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
    /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
    /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
    /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
    /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
    /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
    /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
    /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
    /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em 0 #000;
}
.construction {
  -webkit-animation: work 0.375s steps(1, start) infinite;
  animation: work 0.375s steps(1, start) infinite;
  background-color: #000;
  font-size: 7px; /* pixel size */
  height: 1em;
  margin-bottom: 37em;
  margin-left: 17em;
  position: relative;
  width: 1em;
  display: block;

  /* Remove this is you don't care about a fallback image */

  box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em,
    2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
    -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
    13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -8em 12em,
    -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em, -2em 12em, 0 12em,
    1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em, /* row 14 */ -13em 13em,
    -12em 13em, -8em 13em, -4em 13em, -3em 13em, -2em 13em, -1em 13em, 15em 13em,
    16em 13em, /* row 15 */ -14em 14em, -13em 14em, -7em 14em, -5em 14em,
    -4em 14em, -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
    /* row 16 */ -15em 15em, -14em 15em, -7em 15em, -6em 15em, -5em 15em,
    -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
    /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em, -5em 16em,
    -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em, /* row 18 */ -17em 17em,
    -16em 17em, -8em 17em, -7em 17em, -6em 17em, -5em 17em, -4em 17em, -3em 17em,
    0 17em, 19em 17em, 20em 17em, /* row 19 */ -17em 18em, -16em 18em, -8em 18em,
    -7em 18em, -6em 18em, -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em,
    20em 18em, /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em,
    -6em 19em, -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
    /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
    -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
    /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
    -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
    /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
    -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
    /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
    -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
    /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
    -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
    16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
    -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
    7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
    -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
    5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
}

/* Animation */

@-webkit-keyframes work {
  25% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em,
      -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em,
      15em 12em, /* row 14 */ -13em 13em, -12em 13em, -8em 13em, -4em 13em,
      -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -7em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -7em 15em, -6em 15em, -5em 15em,
      -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em, -5em 16em,
      -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, -3em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
  50% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -9em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em,
      -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em,
      14em 12em, 15em 12em, /* row 14 */ -13em 13em, -12em 13em, -9em 13em,
      -4em 13em, -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -8em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -7em 15em, -6em 15em, -5em 15em,
      -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em, -5em 16em,
      -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, -2em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -1em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 1em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 3em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em,
      -8em 23em, -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 4em 23em,
      5em 23em, 6em 23em, 7em 23em, 8em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 7em 24em,
      8em 24em, 15em 24em, 16em 24em, /* row 26 */ -12em 25em, -11em 25em,
      -8em 25em, -7em 25em, -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em,
      5em 25em, 6em 25em, 7em 25em, 8em 25em, 14em 25em, 15em 25em,
      /* row 27 */ -11em 26em, -10em 26em, -8em 26em, -7em 26em, -6em 26em,
      -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em, 6em 26em, 7em 26em,
      8em 26em, 13em 26em, 14em 26em 0 #000;
  }

  75% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -9em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em,
      -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em,
      14em 12em, 15em 12em, /* row 14 */ -13em 13em, -12em 13em, -9em 13em,
      -4em 13em, -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -9em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -9em 15em, -7em 15em, -6em 15em,
      -5em 15em, -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -8em 16em, -7em 16em,
      -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -3em 18em, -2em 18em, 0 18em, 6em 18em, 7em 18em,
      19em 18em, 20em 18em, /* row 20 */ -17em 19em, -16em 19em, -8em 19em,
      -7em 19em, -6em 19em, -5em 19em, -4em 19em, -1em 19em, 0 19em, 1em 19em,
      5em 19em, 6em 19em, 7em 19em, 8em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 3em 20em, 4em 20em, 5em 20em,
      6em 20em, 7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 4em 21em, 5em 21em, 6em 21em,
      7em 21em, 8em 21em, 9em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 8em 22em, 9em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 7em 23em, 8em 23em, 9em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
  100% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 9em 10em, 10em 10em, 12em 10em,
      13em 10em, /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em,
      1em 11em, 2em 11em, 3em 11em, 4em 11em, 7em 11em, 9em 11em, 10em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 8em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -9em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 8em 14em, 10em 14em, 16em 14em,
      17em 14em, /* row 16 */ -15em 15em, -14em 15em, -9em 15em, -7em 15em,
      -6em 15em, -5em 15em, -4em 15em, -3em 15em, -2em 15em, 0 15em, 6em 15em,
      10em 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em, -15em 16em,
      -9em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      6em 16em, 8em 16em, 18em 16em, 19em 16em, /* row 18 */ -17em 17em,
      -16em 17em, -9em 17em, -8em 17em, -7em 17em, -6em 17em, -5em 17em,
      -4em 17em, 0 17em, 19em 17em, 20em 17em, /* row 19 */ -17em 18em,
      -16em 18em, -9em 18em, -8em 18em, -7em 18em, -6em 18em, -5em 18em,
      -4em 18em, -3em 18em, -2em 18em, -1em 18em, 0 18em, 1em 18em, 2em 18em,
      3em 18em, 4em 18em, 5em 18em, 6em 18em, 7em 18em, 8em 18em, 9em 18em,
      10em 18em, 11em 18em, 19em 18em, 20em 18em, /* row 20 */ -17em 19em,
      -16em 19em, -8em 19em, -7em 19em, -6em 19em, -5em 19em, -4em 19em, 0 19em,
      6em 19em, 7em 19em, 8em 19em, 9em 19em, 10em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 6em 20em, 7em 20em, 8em 20em, 9em 20em,
      19em 20em, 20em 20em, /* row 22 */ -16em 21em, -15em 21em, -8em 21em,
      -7em 21em, -6em 21em, -5em 21em, -4em 21em, -3em 21em, -2em 21em,
      18em 21em, 19em 21em, /* row 23 */ -15em 22em, -14em 22em, -8em 22em,
      -7em 22em, -6em 22em, -4em 22em, -3em 22em, -2em 22em, -1em 22em,
      17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em, -8em 23em,
      -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
}

@keyframes work {
  25% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em,
      -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em,
      15em 12em, /* row 14 */ -13em 13em, -12em 13em, -8em 13em, -4em 13em,
      -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -7em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -7em 15em, -6em 15em, -5em 15em,
      -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em, -5em 16em,
      -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, -3em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
  50% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -9em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em,
      -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em,
      14em 12em, 15em 12em, /* row 14 */ -13em 13em, -12em 13em, -9em 13em,
      -4em 13em, -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -8em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -7em 15em, -6em 15em, -5em 15em,
      -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em, -5em 16em,
      -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, -2em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -1em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 1em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 3em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em,
      -8em 23em, -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 4em 23em,
      5em 23em, 6em 23em, 7em 23em, 8em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 7em 24em,
      8em 24em, 15em 24em, 16em 24em, /* row 26 */ -12em 25em, -11em 25em,
      -8em 25em, -7em 25em, -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em,
      5em 25em, 6em 25em, 7em 25em, 8em 25em, 14em 25em, 15em 25em,
      /* row 27 */ -11em 26em, -10em 26em, -8em 26em, -7em 26em, -6em 26em,
      -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em, 6em 26em, 7em 26em,
      8em 26em, 13em 26em, 14em 26em 0 #000;
  }

  75% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
      -11em 12em, -9em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em,
      -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em,
      14em 12em, 15em 12em, /* row 14 */ -13em 13em, -12em 13em, -9em 13em,
      -4em 13em, -3em 13em, -2em 13em, -1em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -9em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 16em 14em, 17em 14em,
      /* row 16 */ -15em 15em, -14em 15em, -9em 15em, -7em 15em, -6em 15em,
      -5em 15em, -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -8em 16em, -7em 16em,
      -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -3em 18em, -2em 18em, 0 18em, 6em 18em, 7em 18em,
      19em 18em, 20em 18em, /* row 20 */ -17em 19em, -16em 19em, -8em 19em,
      -7em 19em, -6em 19em, -5em 19em, -4em 19em, -1em 19em, 0 19em, 1em 19em,
      5em 19em, 6em 19em, 7em 19em, 8em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 3em 20em, 4em 20em, 5em 20em,
      6em 20em, 7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 4em 21em, 5em 21em, 6em 21em,
      7em 21em, 8em 21em, 9em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 8em 22em, 9em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 7em 23em, 8em 23em, 9em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
  100% {
    box-shadow:
    /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em,
      1em 10em, 2em 10em, 3em 10em, 4em 10em, 9em 10em, 10em 10em, 12em 10em,
      13em 10em, /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em,
      1em 11em, 2em 11em, 3em 11em, 4em 11em, 7em 11em, 9em 11em, 10em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 8em 13em, 15em 13em, 16em 13em,
      /* row 15 */ -14em 14em, -13em 14em, -9em 14em, -5em 14em, -4em 14em,
      -3em 14em, -2em 14em, -1em 14em, 0 14em, 8em 14em, 10em 14em, 16em 14em,
      17em 14em, /* row 16 */ -15em 15em, -14em 15em, -9em 15em, -7em 15em,
      -6em 15em, -5em 15em, -4em 15em, -3em 15em, -2em 15em, 0 15em, 6em 15em,
      10em 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em, -15em 16em,
      -9em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      6em 16em, 8em 16em, 18em 16em, 19em 16em, /* row 18 */ -17em 17em,
      -16em 17em, -9em 17em, -8em 17em, -7em 17em, -6em 17em, -5em 17em,
      -4em 17em, 0 17em, 19em 17em, 20em 17em, /* row 19 */ -17em 18em,
      -16em 18em, -9em 18em, -8em 18em, -7em 18em, -6em 18em, -5em 18em,
      -4em 18em, -3em 18em, -2em 18em, -1em 18em, 0 18em, 1em 18em, 2em 18em,
      3em 18em, 4em 18em, 5em 18em, 6em 18em, 7em 18em, 8em 18em, 9em 18em,
      10em 18em, 11em 18em, 19em 18em, 20em 18em, /* row 20 */ -17em 19em,
      -16em 19em, -8em 19em, -7em 19em, -6em 19em, -5em 19em, -4em 19em, 0 19em,
      6em 19em, 7em 19em, 8em 19em, 9em 19em, 10em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 6em 20em, 7em 20em, 8em 20em, 9em 20em,
      19em 20em, 20em 20em, /* row 22 */ -16em 21em, -15em 21em, -8em 21em,
      -7em 21em, -6em 21em, -5em 21em, -4em 21em, -3em 21em, -2em 21em,
      18em 21em, 19em 21em, /* row 23 */ -15em 22em, -14em 22em, -8em 22em,
      -7em 22em, -6em 22em, -4em 22em, -3em 22em, -2em 22em, -1em 22em,
      17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em, -8em 23em,
      -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em 0 #000;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #060e0c inset !important;
}

.MuiChartsLegend-label.css-zeh0io-MuiChartsLegend-label {
  fill: #fff;
}

.css-66gjpw-MuiResponsiveChart-container {
  width: 300px !important;
  height: 150px !important;
}

/* .css-13cymwt-control,
.css-1nmdiq5-menu {
  width: 12rem;
} */
.react-select-container {
  width: 12rem;
}

.chrome-picker {
  position: absolute !important;
  bottom: 25px;
  z-index: 1;
}

.filterreactselect {
  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: white;

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}

/* pagination */
.pagination {
  margin-top: 1.2rem !important;
  --cui-pagination-padding-x: 0.75rem;
  --cui-pagination-padding-y: 0.375rem;
  --cui-pagination-font-size: 1rem;
  --cui-pagination-color: var(--cui-link-color);
  --cui-pagination-bg: #fff;
  --cui-pagination-border-width: 1px;
  --cui-pagination-border-color: #c4c9d0;
  --cui-pagination-border-radius: 0.375rem;
  --cui-pagination-hover-color: var(--cui-link-hover-color);
  --cui-pagination-hover-bg: #d8dbe0;
  --cui-pagination-hover-border-color: #c4c9d0;
  --cui-pagination-focus-color: var(--cui-link-hover-color);
  --cui-pagination-focus-bg: #d8dbe0;
  --cui-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
  --cui-pagination-active-color: rgba(255, 255, 255, 0.87);
  --cui-pagination-active-bg: #321fdb;
  --cui-pagination-active-border-color: #321fdb;
  --cui-pagination-disabled-color: #8a93a2;
  --cui-pagination-disabled-bg: #fff;
  --cui-pagination-disabled-border-color: #c4c9d0;
  display: flex;
  list-style: none;
}
html:not([dir="rtl"]) .pagination {
  padding-left: 0;
}
*[dir="rtl"] .pagination {
  padding-right: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--cui-pagination-padding-y) var(--cui-pagination-padding-x);
  font-size: var(--cui-pagination-font-size);
  color: var(--cui-pagination-color);
  text-decoration: none;
  background-color: var(--cui-pagination-bg);
  border: var(--cui-pagination-border-width) solid
    var(--cui-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--cui-pagination-hover-color);
  background-color: var(--cui-pagination-hover-bg);
  border-color: var(--cui-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--cui-pagination-focus-color);
  background-color: var(--cui-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--cui-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--cui-pagination-active-color);
  background-color: var(--cui-pagination-active-bg);
  border-color: var(--cui-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--cui-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--cui-pagination-disabled-bg);
  border-color: var(--cui-pagination-disabled-border-color);
}

.page-item .disabled {
  font-size: 1.6rem;
}

html:not([dir="rtl"]) .page-item:not(:first-child) .page-link {
  margin-left: -1px;
  /* font-size: 1.6rem; */
}
*[dir="rtl"] .page-item:not(:first-child) .page-link {
  margin-right: -1px;
}
html:not([dir="rtl"]) .page-item:first-child .page-link {
  border-top-left-radius: var(--cui-pagination-border-radius);
  border-bottom-left-radius: var(--cui-pagination-border-radius);
}
*[dir="rtl"] .page-item:first-child .page-link {
  border-top-right-radius: var(--cui-pagination-border-radius);
  border-bottom-right-radius: var(--cui-pagination-border-radius);
}
html:not([dir="rtl"]) .page-item:last-child .page-link {
  border-top-right-radius: var(--cui-pagination-border-radius);
  border-bottom-right-radius: var(--cui-pagination-border-radius);
}
*[dir="rtl"] .page-item:last-child .page-link {
  border-top-left-radius: var(--cui-pagination-border-radius);
  border-bottom-left-radius: var(--cui-pagination-border-radius);
}

.pagination-lg {
  --cui-pagination-padding-x: 1.5rem;
  --cui-pagination-padding-y: 0.75rem;
  --cui-pagination-font-size: 1.25rem;
  --cui-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --cui-pagination-padding-x: 0.5rem;
  --cui-pagination-padding-y: 0.25rem;
  --cui-pagination-font-size: 0.875rem;
  --cui-pagination-border-radius: 0.25rem;
}

.css-yyp6vh-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.MuiButtonBase-root.Mui-disabled {
  pointer: none !important;
  opacity: 1 !important;
  background: transparent !important;
  outline: none !important;
  border: none !important;
}

.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  color: #fff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: /*your box-shadow*/ 0 0 0 50px white inset;
  -webkit-text-fill-color: #fff;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 99;
}

.ReactModal__Content {
  background: #000 !important;
  width: 40%;
}

.Toastify {
  font-size: 2rem !important;
}

.css-1lp2p8j-MuiChartsLegend-label {
  fill: #fff !important;
}

.css-18ftw0b-MuiChartsSurface-root {
  view-box: 0 0 200 200 !important;
}
.MuiChartsLegend-label css-1lp2p8j-MuiChartsLegend-label {
  font-size: 1.4rem !important;
}

.css-1lp2p8j-MuiChartsLegend-label {
  font-size: 1.4rem !important;
}

.css-66gjpw-MuiResponsiveChart-container {
  margin-left: -270px !important;
  width: auto !important;
}

text.MuiChartsLegend-label.css-13xjf8z{
  fill: #fff !important;
}
.css-eh057r{
  width: 630px !important;
  margin-left: -200px !important;
}